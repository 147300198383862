// Initial market data and methods for market evolution
import { generatePerlinNoise } from '../utils/calculations';

// Base asset templates for different categories
const ASSET_TEMPLATES = {
  stocks: [
    { symbol: 'TECH', name: 'TechCorp', basePrice: 100, volatility: 0.12 },
    { symbol: 'ENRG', name: 'EnergyInc', basePrice: 75, volatility: 0.12 },
    { symbol: 'FNCE', name: 'FinanceGroup', basePrice: 150, volatility: 0.10 },
    { symbol: 'HLTH', name: 'HealthCare', basePrice: 200, volatility: 0.08 },
    { symbol: 'RETL', name: 'RetailCorp', basePrice: 50, volatility: 0.20 }
  ],
  etfs: [
    { symbol: 'INDX', name: 'Market Index', basePrice: 200, volatility: 0.04 },
    { symbol: 'TECH', name: 'Tech Sector', basePrice: 180, volatility: 0.08 },
    { symbol: 'DIVD', name: 'Dividend Fund', basePrice: 150, volatility: 0.04 },
    { symbol: 'GROW', name: 'Growth Fund', basePrice: 250, volatility: 0.12 }
  ],
  commodities: [
    { symbol: 'GOLD', name: 'Gold', basePrice: 1800, volatility: 0.03 },
    { symbol: 'OIL', name: 'Crude Oil', basePrice: 75, volatility: 0.15 },
    { symbol: 'SILV', name: 'Silver', basePrice: 25, volatility: 0.08 }
  ],
  crypto: [
    { symbol: 'BTC', name: 'Bitcoin', basePrice: 45000, volatility: 0.20 },
    { symbol: 'ETH', name: 'Ethereum', basePrice: 3000, volatility: 0.30 },
    { symbol: 'DOT', name: 'Polkadot', basePrice: 20, volatility: 0.35 }
  ]
};

// Potential IPO candidates
const IPO_CANDIDATES = {
  stocks: [
    { symbol: 'ROBO', name: 'RoboticsCo', basePrice: 80, volatility: 0.18 },
    { symbol: 'SPACE', name: 'SpaceTech', basePrice: 120, volatility: 0.25 },
    { symbol: 'GREEN', name: 'GreenEnergy', basePrice: 90, volatility: 0.15 }
  ],
  etfs: [
    { symbol: 'META', name: 'Metaverse Fund', basePrice: 160, volatility: 0.15 },
    { symbol: 'INNOV', name: 'Innovation Fund', basePrice: 200, volatility: 0.18 }
  ],
  crypto: [
    { symbol: 'SOL', name: 'Solana', basePrice: 100, volatility: 0.40 },
    { symbol: 'AVAX', name: 'Avalanche', basePrice: 80, volatility: 0.35 }
  ]
};

// Market manipulation methods
export const MarketManager = {
  // Initialize market with base assets
  getInitialMarketData() {
    return Object.keys(ASSET_TEMPLATES).reduce((acc, category) => {
      acc[category] = ASSET_TEMPLATES[category].map(asset => ({
        ...asset,
        price: asset.basePrice,
        previousPrice: asset.basePrice,
        status: 'active'
      }));
      return acc;
    }, {});
  },

  // Add new asset through IPO
  generateIPO(category) {
    if (!IPO_CANDIDATES[category] || IPO_CANDIDATES[category].length === 0) return null;
    
    const candidateIndex = Math.floor(Math.random() * IPO_CANDIDATES[category].length);
    const candidate = IPO_CANDIDATES[category][candidateIndex];
    
    // Remove the candidate from the pool
    IPO_CANDIDATES[category].splice(candidateIndex, 1);
    
    return {
      ...candidate,
      price: candidate.basePrice,
      previousPrice: candidate.basePrice,
      status: 'active'
    };
  },

  // Handle asset failure
  handleAssetFailure(asset) {
    return {
      ...asset,
      price: asset.price * 0.1, // 90% loss
      previousPrice: asset.price,
      status: 'failed'
    };
  },

  // Handle asset acquisition
  handleAssetAcquisition(asset) {
    return {
      ...asset,
      price: asset.price * 1.2, // 20% premium
      previousPrice: asset.price,
      status: 'acquired'
    };
  },

  // Calculate market metrics for a category
  calculateMarketMetrics(assets) {
    if (!assets || assets.length === 0) return null;
    
    return {
      totalMarketCap: assets.reduce((sum, asset) => sum + asset.price, 0),
      averagePrice: assets.reduce((sum, asset) => sum + asset.price, 0) / assets.length,
      volatility: Math.max(...assets.map(asset => asset.volatility))
    };
  }
};

export default MarketManager;
