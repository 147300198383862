const STORAGE_KEY = 'stonks_clicker_save#325';

export const saveGameState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORAGE_KEY, serializedState);
  } catch (err) {
    console.error('Failed to save game state:', err);
  }
};

export const loadGameState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_KEY);
    if (!serializedState) return null;
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Failed to load game state:', err);
    return null;
  }
};

export const clearGameState = () => {
  try {
    // First nullify the current state
    localStorage.setItem(STORAGE_KEY, 'null');
    // Then remove it
    localStorage.removeItem(STORAGE_KEY);
    return true;
  } catch (err) {
    console.error('Failed to clear game state:', err);
    return false;
  }
};
