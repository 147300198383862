import { Box, Typography, Stack, Chip, useTheme, Button } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { formatLargeNumber } from '../../utils/formatters';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GroupIcon from '@mui/icons-material/Group';
import ThemeToggle from './ThemeToggle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { clearGameState } from '../../services/storageService';

const StatChip = ({ icon, label, value }) => {
  const theme = useTheme();
  
  return (
    <Chip
      icon={icon}
      label={`${label}: ${value}`}
      sx={{
        height: 'auto',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& .MuiChip-label': {
          display: 'block',
          padding: '10px 14px',
          fontSize: '0.875rem'
        },
        '& .MuiChip-icon': {
          color: 'inherit'
        },
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: theme.shadows[2]
        }
      }}
    />
  );
};

const Header = ({ isDarkMode, onThemeToggle }) => {
  const { state, dispatch } = useGameState();
  const theme = useTheme();
  
  const handleResetGame = () => {
    if (window.confirm('Are you sure you want to reset your progress? This cannot be undone!')) {
      try {
        // First set game state to null to free memory
        dispatch({ type: 'RESET_GAME' });
        
        // Then clear storage
        const cleared = clearGameState();
        
        if (cleared) {
          // Use a simpler reload approach
          window.location.reload();
        } else {
          console.error('Failed to clear game state');
          alert('Failed to reset game. Please try refreshing the page manually.');
        }
      } catch (error) {
        console.error('Error during game reset:', error);
        alert('An error occurred while resetting the game.');
      }
    }
  };
  
  return (
    <Box
      component="header"
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        borderBottom: 1,
        borderColor: 'divider',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        boxShadow: theme.shadows[1]
      }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        sx={{ 
          px: { xs: 2, sm: 3 },
          py: { xs: 1.5, sm: 2 }
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography 
            variant="h4" 
            sx={{ 
              mb: { xs: 1, sm: 0 },
              fontWeight: 600,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Business Clicker
          </Typography>
          <ThemeToggle isDarkMode={isDarkMode} onToggle={onThemeToggle} />
        </Stack>

        <Box
          sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            width: { xs: '100%', sm: 'auto' },
            pb: { xs: 1, sm: 0 },
            '::-webkit-scrollbar': {
              display: 'none'
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }}
        >
          <StatChip
            icon={<AttachMoneyIcon />}
            label="Money"
            value={formatLargeNumber(state.money)}
          />
          <StatChip
            icon={<TrendingUpIcon />}
            label="Per Click"
            value={formatLargeNumber(state.clickValue)}
          />
          <StatChip
            icon={<GroupIcon />}
            label="Staff Income"
            value={`${formatLargeNumber(state.incomePerSecond)}/s`}
          />
        </Box>

        <Button
          variant="outlined"
          color="error"
          onClick={handleResetGame}
          startIcon={<RestartAltIcon />}
          size="small"
        >
          Reset Game
        </Button>
      </Stack>
    </Box>
  );
};

export default Header;