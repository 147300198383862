import { Box, Stack, Grid } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useMarket } from '../../contexts/MarketContext';
import MarketCycle from './MarketCycle/CycleIndicator';
import NewsFeed from './MarketNews/NewsFeed';
import AssetTable from './Trading/AssetTable';
import Portfolio from './Portfolio/PortfolioSummary';
import PortfolioChart from './Portfolio/PortfolioChart';
import AssetBreakdown from './Portfolio/AssetBreakdown';

const MarketContent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { state } = useMarket();
  
  if (!state || !state.assets) {
    return <Box>Loading market data...</Box>;
  }

  return (
    <Grid 
      container 
      spacing={3} 
      sx={{ 
        p: { xs: 0.5, sm: 2 }  // Reduce padding on mobile
      }}
    >
      {/* Market Overview Section */}
      <Grid item xs={12} md={isMobile ? 12 : 8}>
        <Stack spacing={3} sx={{ 
          // Add negative margin to counteract container padding on mobile
          mx: { xs: -0.5, sm: 0 }
        }}>
          <MarketCycle />
          
          {/* Asset Tables Section */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 3,
            '& > *': {
              boxShadow: (theme) => `0 2px 8px ${theme.palette.primary.main}10`,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
              // Remove border radius on mobile
              borderRadius: { xs: 0, sm: 1 }
            }
          }}>
            <AssetTable category="stocks" />
            <AssetTable category="etfs" />
            <AssetTable category="commodities" />
            <AssetTable category="crypto" />
          </Box>
        </Stack>
      </Grid>

      {/* Portfolio Section */}
      <Grid item xs={12} md={isMobile ? 12 : 4}>
        <Stack spacing={3} sx={{ 
          // Add negative margin to counteract container padding on mobile
          mx: { xs: -0.5, sm: 0 }
        }}>
          <Box sx={{ 
            '& > *': {
              mb: 3,
              boxShadow: (theme) => `0 2px 8px ${theme.palette.primary.main}10`,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
              // Remove border radius on mobile
              borderRadius: { xs: 0, sm: 1 }
            }
          }}>
            <Portfolio />
            <PortfolioChart />
            <AssetBreakdown />
            <NewsFeed />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MarketContent;
