export const GAME_CONSTANTS = {
  // Work & Training Constants
  WORK: {
    BASE_CLICK_VALUE: 2,
    TRAINING: {
      BASE_COST: 25,
      COST_MULTIPLIER: 1.4,
      VALUE_MULTIPLIER: 1.2,
    }
  },

  // Staff Constants
  STAFF: {
    INTERN: {
      id: 'intern',
      name: 'Intern',
      BASE_COST: 25,
      COST_MULTIPLIER: 1.15,
      BASE_INCOME: 0.3,
      INCOME_MULTIPLIER: 1.08,
      requirement: null
    },
    JANITOR: {
      id: 'janitor', 
      name: 'Janitor',
      BASE_COST: 150,
      COST_MULTIPLIER: 1.16,
      BASE_INCOME: 0.8,
      INCOME_MULTIPLIER: 1.09,
      requirement: {
        staff: { intern: 3 }
      }
    },
    RECEPTIONIST: {
      id: 'receptionist',
      name: 'Receptionist', 
      BASE_COST: 500,
      COST_MULTIPLIER: 1.17,
      BASE_INCOME: 1,
      INCOME_MULTIPLIER: 1.10,
      requirement: {
        staff: { janitor: 5 }
      }
    },
    WORKER: {
      id: 'worker',
      name: 'Worker',
      BASE_COST: 1000,
      COST_MULTIPLIER: 1.18,
      BASE_INCOME: 2,
      INCOME_MULTIPLIER: 1.11,
      requirement: {
        staff: { receptionist: 5 }
      }
    },
    ACCOUNTANT: {
      id: 'accountant',
      name: 'Accountant',
      BASE_COST: 2500,
      COST_MULTIPLIER: 1.19,
      BASE_INCOME: 5,
      INCOME_MULTIPLIER: 1.12,
      requirement: {
        staff: { worker: 5 }
      }
    },
    OFFICE_MANAGER: {
      id: 'office_manager',
      name: 'Office Manager',
      BASE_COST: 4000,
      COST_MULTIPLIER: 1.19,
      BASE_INCOME: 8,
      INCOME_MULTIPLIER: 1.12,
      requirement: {
        staff: { accountant: 3 }
      }
    },
    HR_SPECIALIST: {
      id: 'hr_specialist',
      name: 'HR Specialist',
      BASE_COST: 5000,
      COST_MULTIPLIER: 1.20,
      BASE_INCOME: 10,
      INCOME_MULTIPLIER: 1.13,
      requirement: {
        staff: { office_manager: 5 }
      }
    },
    MANAGER: {
      id: 'manager',
      name: 'Manager',
      BASE_COST: 10000,
      COST_MULTIPLIER: 1.21,
      BASE_INCOME: 20,
      INCOME_MULTIPLIER: 1.14,
      requirement: {
        staff: { hr_specialist: 5 }
      }
    },
    PROJECT_LEAD: {
      id: 'project_lead',
      name: 'Project Lead',
      BASE_COST: 25000,
      COST_MULTIPLIER: 1.22,
      BASE_INCOME: 50,
      INCOME_MULTIPLIER: 1.15,
      requirement: {
        staff: { manager: 5 }
      }
    },
    ENGINEER: {
      id: 'engineer',
      name: 'Engineer',
      BASE_COST: 100000,
      COST_MULTIPLIER: 1.23,
      BASE_INCOME: 200,
      INCOME_MULTIPLIER: 1.16,
      requirement: {
        staff: { project_lead: 5 }
      }
    },
    SYSTEMS_ARCHITECT: {
      id: 'systems_architect',
      name: 'Systems Architect',
      BASE_COST: 175000,
      COST_MULTIPLIER: 1.23,
      BASE_INCOME: 350,
      INCOME_MULTIPLIER: 1.16,
      requirement: {
        staff: { engineer: 3 }
      }
    },
    DATA_SCIENTIST: {
      id: 'data_scientist',
      name: 'Data Scientist',
      BASE_COST: 250000,
      COST_MULTIPLIER: 1.24,
      BASE_INCOME: 500,
      INCOME_MULTIPLIER: 1.17,
      requirement: {
        staff: { systems_architect: 5 }
      }
    },
    PRODUCT_MANAGER: {
      id: 'product_manager',
      name: 'Product Manager',
      BASE_COST: 500000,
      COST_MULTIPLIER: 1.25,
      BASE_INCOME: 1000,
      INCOME_MULTIPLIER: 1.18,
      requirement: {
        staff: { data_scientist: 5 }
      }
    },
    INNOVATION_DIRECTOR: {
      id: 'innovation_director',
      name: 'Innovation Director',
      BASE_COST: 750000,
      COST_MULTIPLIER: 1.25,
      BASE_INCOME: 1500,
      INCOME_MULTIPLIER: 1.18,
      requirement: {
        staff: { product_manager: 3 }
      }
    },
    CEO: {
      id: 'ceo',
      name: 'CEO',
      BASE_COST: 1000000,
      COST_MULTIPLIER: 1.26,
      BASE_INCOME: 2000,
      INCOME_MULTIPLIER: 1.19,
      requirement: {
        staff: { innovation_director: 5 }
      }
    },
    BOARD_MEMBER: {
      id: 'board_member',
      name: 'Board Member',
      BASE_COST: 5000000,
      COST_MULTIPLIER: 1.27,
      BASE_INCOME: 10000,
      INCOME_MULTIPLIER: 1.20,
      requirement: {
        staff: { ceo: 5 }
      }
    }
  },

  // Market Unlock Constants
  MARKET: {
    UNLOCK_COST: 750000,
    UNLOCK_REQUIREMENT: 750000,
    MIN_INVESTMENT: 500,
    UPDATE_INTERVAL: 4000,
    VOLATILITY: {
      LOW: 0.04,
      MEDIUM: 0.12,
      HIGH: 0.20
    },
    CYCLE: {
      BULL_BIAS: 0.85,
      BASE_VOLATILITY: 0.6,
      TREND_STRENGTH: 1.5,
      NOISE_WEIGHT: 0.35,
      PHASE_HYSTERESIS: 0.25
    }
  },

  // Game Progression Milestones
  MILESTONES: {
    FIRST_WORKER: 1000,
    FIRST_MANAGER: 10000,
    MARKET_HINT: 500000
  },

  UPGRADES: {
    GLOBAL: [
      // Early Game Upgrades
      {
        id: 'better_chairs',
        name: 'Ergonomic Chairs',
        description: 'Basic comfort increases productivity by 10%',
        cost: 1500,
        multiplier: 1.15,
        requirement: {
          staff: { intern: 8 }
        },
        affects: ['intern', 'janitor', 'receptionist']
      },
      {
        id: 'new_desks',
        name: 'New Desks',
        description: 'Better workspaces increase productivity by 10%',
        cost: 5000,
        multiplier: 1.1,
        requirement: {
          staff: { janitor: 5 }
        },
        affects: 'all'
      },
      {
        id: 'water_cooler',
        name: 'Water Cooler',
        description: 'Hydrated workers are 12% more productive',
        cost: 7500,
        multiplier: 1.12,
        requirement: {
          staff: { worker: 5 }
        },
        affects: ['intern', 'worker', 'receptionist']
      },
      {
        id: 'coffee_machine',
        name: 'Coffee Machine',
        description: 'Caffeinated staff are 15% more productive',
        cost: 15000,
        multiplier: 1.15,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },

      // Mid Game Upgrades (25k-100k)
      {
        id: 'training_program',
        name: 'Training Program',
        description: 'Entry-level staff 25% more productive',
        cost: 50000,
        multiplier: 1.25,
        requirement: {
          staff: { intern: 5, janitor: 5, receptionist: 5, worker: 5 }
        },
        affects: ['intern', 'janitor', 'receptionist', 'worker']
      },
      {
        id: 'accounting_software',
        name: 'Accounting Software',
        description: 'Financial staff 30% more efficient',
        cost: 75000,
        multiplier: 1.3,
        requirement: {
          staff: { accountant: 3 }
        },
        affects: ['accountant', 'hr_specialist']
      },
      {
        id: 'management_software',
        name: 'Management Software',
        description: 'Management 30% more productive',
        cost: 200000,
        multiplier: 1.3,
        requirement: {
          staff: { manager: 5 }
        },
        affects: ['manager', 'project_lead']
      },

      // Late Game Upgrades (100k-1M)
      {
        id: 'premium_coffee',
        name: 'Premium Coffee Subscription',
        description: 'Fancy coffee makes everyone 20% more productive',
        cost: 150000,
        multiplier: 1.2,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },
      {
        id: 'development_tools',
        name: 'Advanced Dev Tools',
        description: 'Technical staff 40% more productive',
        cost: 300000,
        multiplier: 1.4,
        requirement: {
          staff: { engineer: 5, data_scientist: 5 }
        },
        affects: ['engineer', 'data_scientist']
      },
      {
        id: 'office_renovation',
        name: 'Office Renovation',
        description: 'Beautiful office increases all productivity by 25%',
        cost: 500000,
        multiplier: 1.25,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },

      // End Game Upgrades (1M+)
      {
        id: 'ai_assistant',
        name: 'AI Assistant Integration',
        description: 'AI helps everyone work 35% faster',
        cost: 1000000,
        multiplier: 1.35,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },
      {
        id: 'satellite_office',
        name: 'Satellite Office',
        description: 'Expansion increases high-level productivity by 50%',
        cost: 2000000,
        multiplier: 1.5,
        requirement: {
          staff: { product_manager: 5, ceo: 5, board_member: 5 }
        },
        affects: ['product_manager', 'ceo', 'board_member']
      },

      // Quality of Life Upgrades
      {
        id: 'snack_bar',
        name: 'Snack Bar',
        description: 'Free snacks boost morale and productivity by 15%',
        cost: 25000,
        multiplier: 1.15,
        requirement: {
          staff: { intern: 5, janitor: 5, receptionist: 5, worker: 5 }
        },
        affects: ['intern', 'janitor', 'receptionist', 'worker']
      },
      {
        id: 'gym_membership',
        name: 'Corporate Gym Membership',
        description: 'Healthy workers are 20% more productive',
        cost: 100000,
        multiplier: 1.2,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },
      {
        id: 'mental_health',
        name: 'Mental Health Program',
        description: 'Happy workers are 25% more productive',
        cost: 250000,
        multiplier: 1.25,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },

      // Technology Upgrades
      {
        id: 'faster_internet',
        name: 'Fiber Internet',
        description: 'Faster internet increases productivity by 20%',
        cost: 125000,
        multiplier: 1.2,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },
      {
        id: 'second_monitor',
        name: 'Dual Monitors',
        description: 'More screen space increases productivity by 25%',
        cost: 175000,
        multiplier: 1.25,
        requirement: {
          staff: { engineer: 5, data_scientist: 5, product_manager: 5 }
        },
        affects: ['engineer', 'data_scientist', 'product_manager']
      },
      {
        id: 'cloud_services',
        name: 'Cloud Infrastructure',
        description: 'Better infrastructure increases productivity by 30%',
        cost: 400000,
        multiplier: 1.3,
        requirement: {
          staff: { all: 5 }
        },
        affects: 'all'
      },

      // Prestige Upgrades
      {
        id: 'company_cars',
        name: 'Company Cars',
        description: 'Luxury perks increase executive productivity by 40%',
        cost: 1500000,
        multiplier: 1.4,
        requirement: {
          staff: { ceo: 5, board_member: 5 }
        },
        affects: ['ceo', 'board_member']
      },
      {
        id: 'private_jet',
        name: 'Private Jet',
        description: 'Ultimate luxury increases board productivity by 100%',
        cost: 5000000,
        multiplier: 2.0,
        requirement: {
          staff: { board_member: 5 }
        },
        affects: ['board_member']
      }
    ]
  }
};