// News templates and generation logic
export const NEWS_TEMPLATES = {
  market: {
    bull: [
      "{symbol} surges {percent}% as market sentiment improves",
      "Investors flock to {symbol}, driving {percent}% rally",
      "{symbol} breaks resistance with {percent}% gain",
      "Strong earnings push {symbol} up {percent}%",
      "{sector} sector leader {symbol} advances {percent}%",
      "Analyst upgrade sends {symbol} soaring {percent}%"
    ],
    bear: [
      "{symbol} drops {percent}% amid market pressure",
      "Selling pressure hits {symbol}, down {percent}%",
      "{symbol} falls {percent}% on profit-taking",
      "Market uncertainty weighs on {symbol}, loses {percent}%",
      "{sector} weakness pulls {symbol} down {percent}%",
      "Analyst downgrade pushes {symbol} {percent}% lower"
    ],
    neutral: [
      "{symbol} consolidates after recent volatility",
      "Trading sideways: {symbol} seeks direction",
      "{symbol} stable as investors assess market conditions",
      "{sector} sector steady, {symbol} maintains position",
      "{symbol} volume drops as traders await catalyst",
      "Mixed signals keep {symbol} range-bound"
    ]
  },
  events: {
    ipo: [
      "Breaking: {symbol} announces IPO plans",
      "New listing alert: {symbol} to debut next week",
      "{symbol} IPO generates strong investor interest",
      "Market welcomes {symbol} with anticipated IPO",
      "{sector} expands as {symbol} prepares public offering"
    ],
    merger: [
      "Merger talks boost {symbol} prospects",
      "{symbol} considers strategic acquisition",
      "Industry consolidation: {symbol} in merger discussions",
      "{symbol} explores strategic alternatives",
      "Market speculates on {symbol} merger potential"
    ],
    regulatory: [
      "Regulatory review impacts {symbol} outlook",
      "New regulations affect {symbol} operations",
      "{symbol} adapts to regulatory changes",
      "Compliance update: {symbol} meets new standards",
      "Regulatory clarity benefits {symbol} position"
    ]
  }
};

export const NewsGenerator = {
  generateMarketNews(asset, changePercent, sector) {
    const direction = changePercent > 0.5 ? 'bull' : 
                     changePercent < -0.5 ? 'bear' : 
                     'neutral';
    const templates = NEWS_TEMPLATES.market[direction];
    const template = templates[Math.floor(Math.random() * templates.length)];
    
    return template
      .replace('{symbol}', asset.symbol)
      .replace('{percent}', Math.abs(changePercent).toFixed(1))
      .replace('{sector}', sector || this.getSectorForAsset(asset.symbol));
  },

  generateEventNews(type, details) {
    const templates = NEWS_TEMPLATES.events[type];
    if (!templates) return null;
    
    const template = templates[Math.floor(Math.random() * templates.length)];
    return template
      .replace('{symbol}', details.symbol)
      .replace('{sector}', details.sector || this.getSectorForAsset(details.symbol));
  },

  getSectorForAsset(symbol) {
    // Map symbols to sectors for more contextual news
    const sectorMap = {
      'TECH': 'Technology',
      'FNCE': 'Financial',
      'ENRG': 'Energy',
      'HLTH': 'Healthcare',
      'RETL': 'Retail',
      'BTC': 'Cryptocurrency',
      'ETH': 'Cryptocurrency',
      'GOLD': 'Commodities',
      'OIL': 'Commodities'
    };
    return sectorMap[symbol] || 'General';
  }
};
