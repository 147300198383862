import { Box, Typography, LinearProgress, Grid, Paper } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { formatLargeNumber } from '../../utils/formatters';
import { useState, useEffect } from 'react';

const Achievement = ({ current, target, label, isComplete }) => {
  const progress = Math.min((current / target) * 100, 100);
  
  return (
    <Box sx={{ width: '100%', mr: 1 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 0.5
      }}>
        <Typography variant="caption" color={isComplete ? 'success.main' : 'text.secondary'}>
          {label}
        </Typography>
        <Typography variant="caption" color={isComplete ? 'success.main' : 'text.secondary'}>
          {`${formatLargeNumber(current)} / ${formatLargeNumber(target)}`}
        </Typography>
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={progress}
        sx={{
          height: 4,
          borderRadius: 1,
          bgcolor: 'background.paper',
          '& .MuiLinearProgress-bar': {
            bgcolor: isComplete ? 'success.main' : 'primary.main',
          }
        }}
      />
    </Box>
  );
};

const StatsPanel = () => {
  const { state } = useGameState();
  const [achievements, setAchievements] = useState(() => {
    const saved = localStorage.getItem('achievements');
    return saved ? JSON.parse(saved) : {
      money1k: false,
      money10k: false,
      money100k: false,
      money750k: false,
      employees10: false,
      employees25: false,
      employees50: false,
      employees100: false,
      employees200: false,
    };
  });

  const totalEmployees = Object.values(state.staff || {})
    .reduce((sum, { count }) => sum + count, 0);

  useEffect(() => {
    let updated = false;
    const newAchievements = { ...achievements };

    // Check money achievements
    if (state.money >= 1000 && !achievements.money1k) {
      newAchievements.money1k = true;
      updated = true;
    }
    if (state.money >= 10000 && !achievements.money10k) {
      newAchievements.money10k = true;
      updated = true;
    }
    if (state.money >= 100000 && !achievements.money100k) {
      newAchievements.money100k = true;
      updated = true;
    }
    if (state.money >= 750000 && !achievements.money750k) {
      newAchievements.money750k = true;
      updated = true;
    }

    // Check employee achievements
    if (totalEmployees >= 10 && !achievements.employees10) {
      newAchievements.employees10 = true;
      updated = true;
    }
    if (totalEmployees >= 25 && !achievements.employees25) {
      newAchievements.employees25 = true;
      updated = true;
    }
    if (totalEmployees >= 50 && !achievements.employees50) {
      newAchievements.employees50 = true;
      updated = true;
    }
    if (totalEmployees >= 100 && !achievements.employees100) {
      newAchievements.employees100 = true;
      updated = true;
    }
    if (totalEmployees >= 200 && !achievements.employees200) {
      newAchievements.employees200 = true;
      updated = true;
    }

    if (updated) {
      setAchievements(newAchievements);
      localStorage.setItem('achievements', JSON.stringify(newAchievements));
    }
  }, [state.money, totalEmployees, achievements]);

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 2, 
        mb: 2,
        border: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
      }}
    >
      <Grid container spacing={2}>
        {/* Stats Section */}
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h6" color="primary">Stats</Typography>
            <Typography variant="body2">
              Total Employees: {totalEmployees}
            </Typography>
            <Typography variant="body2">
              Total Money: ${formatLargeNumber(state.money)}
            </Typography>
          </Box>
        </Grid>

        {/* Achievements Section */}
        <Grid item xs={12} md={9}>
          <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
            Achievements
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Achievement 
                current={state.money}
                target={1000}
                label="Reach $1K"
                isComplete={achievements.money1k}
              />
              <Achievement 
                current={state.money}
                target={10000}
                label="Reach $10K"
                isComplete={achievements.money10k}
              />
              <Achievement 
                current={state.money}
                target={100000}
                label="Reach $100K"
                isComplete={achievements.money100k}
              />
              <Achievement 
                current={state.money}
                target={750000}
                label="Unlock Market ($750K)"
                isComplete={achievements.money750k}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Achievement 
                current={totalEmployees}
                target={10}
                label="Hire 10 Employees"
                isComplete={achievements.employees10}
              />
              <Achievement 
                current={totalEmployees}
                target={25}
                label="Hire 25 Employees"
                isComplete={achievements.employees25}
              />
              <Achievement 
                current={totalEmployees}
                target={50}
                label="Hire 50 Employees"
                isComplete={achievements.employees50}
              />
              <Achievement 
                current={totalEmployees}
                target={100}
                label="Hire 100 Employees"
                isComplete={achievements.employees100}
              />
              <Achievement 
                current={totalEmployees}
                target={200}
                label="Hire 200 Employees"
                isComplete={achievements.employees200}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatsPanel;
