import { Card, Stack, Typography, Button } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { GAME_CONSTANTS } from '../../data/gameConstants';
import { formatLargeNumber } from '../../utils/formatters';
import MarketContent from './MarketContent';

const MarketPanel = () => {
  const { state, dispatch } = useGameState();
  const canUnlock = state.money >= GAME_CONSTANTS.MARKET.UNLOCK_COST;

  const handleUnlock = () => {
    if (canUnlock) {
      dispatch({ type: 'UNLOCK_MARKET' });
    }
  };

  // Only show if we meet the requirement to see the market
  if (state.money < GAME_CONSTANTS.MARKET.UNLOCK_REQUIREMENT) {
    return null;
  }

  // Show unlock panel if market isn't unlocked yet
  if (!state.marketUnlocked) {
    return (
      <Card variant="outlined" sx={{ p: 4 }}>
        <Stack spacing={2} alignItems="center">
          <Typography level="h2"> Stock Market</Typography>
          <Typography level="body-lg">
            Unlock access to the financial markets and multiply your wealth!
          </Typography>
          <Typography level="body-md">
            Cost: ${formatLargeNumber(GAME_CONSTANTS.MARKET.UNLOCK_COST)}
          </Typography>
          <Button
            size="lg"
            color={canUnlock ? "success" : "neutral"}
            disabled={!canUnlock}
            onClick={handleUnlock}
            sx={{ minWidth: 200 }}
          >
            {canUnlock ? 'Unlock Market' : 'Not Enough Money'}
          </Button>
        </Stack>
      </Card>
    );
  }

  // Show market content if unlocked
  return <MarketContent />;
};

export default MarketPanel;
