import { GAME_CONSTANTS } from './gameConstants';
import { MarketManager } from './marketDataPrefab';
import { StaffManager } from './staffPrefab';

export const initialGameState = {
  money: 0,
  clickValue: 1,
  clickMultiplier: 1,
  incomePerSecond: 0,
  trainingLevel: 1,
  trainingMultiplier: 1,
  staff: {
    intern: { count: 0, multiplier: 1 },
    worker: { count: 0, multiplier: 1 },
    manager: { count: 0, multiplier: 1 },
    engineer: { count: 0, multiplier: 1 },
    ceo: { count: 0, multiplier: 1 }
  },
  upgrades: [],
  marketUnlocked: false,
  marketState: {
    news: [],
    portfolio: {
      cash: 0,
      positions: {},
      history: []
    }
  }
};