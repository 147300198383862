import { createContext, useContext, useReducer, useEffect } from 'react';
import { MarketManager } from '../data/marketDataPrefab';
import { NewsGenerator } from '../data/newsPrefab';
import { generatePerlinNoise } from '../utils/calculations';
import { GAME_CONSTANTS } from '../data/gameConstants';

const MarketContext = createContext();

const initialMarketState = {
  marketCycle: {
    phase: 'neutral',
    strength: 0.5,
    history: []
  },
  assets: MarketManager.getInitialMarketData(),
  news: [],
  portfolio: {
    positions: {},
    history: [{
      timestamp: Date.now(),
      value: 0
    }]
  }
};

const marketReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_MARKET_CYCLE': {
      const { strength } = action.payload;
      const previousPhase = state.marketCycle.phase;
      
      // More conservative phase switching with hysteresis
      const newPhase = previousPhase === 'bull'
        ? strength < 0.35 ? 'bear' : 'bull'  // Must go quite low to switch to bear
        : strength > 0.65 ? 'bull' : 'bear'; // Must go quite high to switch to bull
      
      return {
        ...state,
        marketCycle: {
          ...state.marketCycle,
          phase: newPhase,
          strength,
          history: [
            ...state.marketCycle.history,
            { timestamp: Date.now(), value: strength }
          ].slice(-100)
        }
      };
    }

    case 'UPDATE_ASSETS': {
      return {
        ...state,
        assets: action.payload
      };
    }

    case 'ADD_NEWS': {
      return {
        ...state,
        news: [action.payload, ...state.news].slice(0, 10)
      };
    }

    case 'EXECUTE_TRADE': {
      const { symbol, shares, price } = action.payload;
      const currentPosition = state.portfolio.positions[symbol] || { shares: 0, avgPrice: 0 };
      const newShares = currentPosition.shares + shares;
      
      // Don't update if shares would go negative
      if (newShares < 0) return state;
      
      // Calculate new average price for buys only
      let newAvgPrice = currentPosition.avgPrice;
      if (shares > 0) {
        newAvgPrice = ((currentPosition.avgPrice * currentPosition.shares) + (price * shares)) / newShares;
      }

      // Create new positions object
      const newPositions = {
        ...state.portfolio.positions
      };

      // Only add position if shares > 0, otherwise delete it
      if (newShares > 0) {
        newPositions[symbol] = {
          shares: newShares,
          avgPrice: newAvgPrice
        };
      } else {
        delete newPositions[symbol];
      }

      // Calculate total portfolio value
      const portfolioValue = Object.entries(newPositions).reduce((total, [sym, pos]) => {
        const asset = Object.values(state.assets)
          .flat()
          .find(a => a.symbol === sym);
        return total + (asset ? asset.price * pos.shares : 0);
      }, 0);

      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          positions: newPositions,
          history: [
            ...state.portfolio.history,
            {
              timestamp: Date.now(),
              value: portfolioValue
            }
          ].slice(-100)
        }
      };
    }

    case 'UPDATE_PORTFOLIO_VALUE': {
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          history: [
            ...state.portfolio.history,
            {
              timestamp: Date.now(),
              value: action.payload.value,
              positions: Object.entries(state.portfolio.positions).reduce((acc, [symbol, position]) => {
                const asset = Object.values(state.assets)
                  .flat()
                  .find(a => a.symbol === symbol);
                acc[symbol] = {
                  shares: position.shares,
                  price: asset.price
                };
                return acc;
              }, {})
            }
          ].slice(-100)
        }
      };
    }

    case 'UPDATE_PORTFOLIO_ATH':
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          allTimeHigh: action.payload
        }
      };

    default:
      return state;
  }
};

export const MarketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(marketReducer, initialMarketState);
  
  // Market cycle updates
  useEffect(() => {
    let noiseOffset = Math.random() * 1000;
    const cycleInterval = setInterval(() => {
      noiseOffset += 0.001; // Reduced from 0.002 to make cycles slower
      
      // Generate smoother noise by averaging multiple samples
      const samples = 5;
      let rawStrength = 0;
      for (let i = 0; i < samples; i++) {
        rawStrength += generatePerlinNoise(noiseOffset + (i * 0.01));
      }
      rawStrength /= samples;
      
      // Apply stronger smoothing using moving average
      const smoothedStrength = state.marketCycle.history.length > 0
        ? (rawStrength * 0.15) + (state.marketCycle.history[0].value * 0.85)  // 85% previous, 15% new
        : rawStrength;
      
      // Apply very gradual bias towards bull market
      const biasedStrength = Math.min(1, smoothedStrength * 1.02); // Reduced bias from 1.05 to 1.02
      
      dispatch({
        type: 'UPDATE_MARKET_CYCLE',
        payload: { strength: biasedStrength }
      });
    }, 5000);

    return () => clearInterval(cycleInterval);
  }, []);

  // Asset price updates
  useEffect(() => {
    const priceInterval = setInterval(() => {
      const cycleStrength = state.marketCycle.strength;
      const marketBias = (cycleStrength - 0.5) * 0.02;

      const updatedAssets = Object.entries(state.assets).reduce((acc, [category, assets]) => {
        acc[category] = assets.map(asset => {
          // Calculate price change based on volatility, market cycle, and random factor
          const volatilityFactor = asset.volatility * (Math.random() * 2 - 1);
          const change = marketBias + volatilityFactor;
          const newPrice = asset.price * (1 + change);

          // Generate news for significant price changes
          if (Math.abs(change) > asset.volatility / 2) {
            dispatch({
              type: 'ADD_NEWS',
              payload: {
                timestamp: Date.now(),
                headline: NewsGenerator.generateMarketNews(
                  asset.name,
                  change * 100,
                  category
                ),
                impact: change > 0 ? 'positive' : 'negative'
              }
            });
          }

          return {
            ...asset,
            previousPrice: asset.price,
            price: newPrice
          };
        });
        return acc;
      }, {});

      // Calculate new portfolio value based on updated prices
      const portfolioValue = Object.entries(state.portfolio.positions).reduce((total, [symbol, position]) => {
        const asset = Object.values(updatedAssets)
          .flat()
          .find(a => a.symbol === symbol);
        return total + (asset ? asset.price * position.shares : 0);
      }, 0);

      // Dispatch both updates
      dispatch({ type: 'UPDATE_ASSETS', payload: updatedAssets });
      
      // Only update portfolio history if there are positions
      if (Object.keys(state.portfolio.positions).length > 0) {
        dispatch({
          type: 'UPDATE_PORTFOLIO_VALUE',
          payload: {
            timestamp: Date.now(),
            value: portfolioValue
          }
        });
      }
    }, 3000);

    return () => clearInterval(priceInterval);
  }, [state.marketCycle.strength, state.portfolio.positions]);

  return (
    <MarketContext.Provider value={{ state, dispatch }}>
      {children}
    </MarketContext.Provider>
  );
};

export const useMarket = () => {
  const context = useContext(MarketContext);
  if (!context) {
    throw new Error('useMarket must be used within a MarketProvider');
  }
  return context;
};
