import { useEffect, useRef, useState } from 'react';
import { Card, Typography, Box } from '@mui/material';
import { Chart } from 'chart.js/auto';
import { useMarket } from '../../../contexts/MarketContext';
import { formatLargeNumber } from '../../../utils/formatters';

// Inline useLocalStorage hook
const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  }, [key, value]);

  return [value, setValue];
};

const PortfolioChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const { state } = useMarket();
  const [legendState, setLegendState] = useLocalStorage('portfolioChartLegend', {
    'Portfolio Value': true,
    'Total Return %': true,
  });

  useEffect(() => {
    if (!chartRef.current || !state?.portfolio?.history) return;
    if (!state.portfolio.history.length) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Calculate cumulative returns
    const returns = state.portfolio.history.map((entry, index) => {
      if (index === 0) return 0;
      const initialValue = state.portfolio.history[0].value;
      return ((entry.value - initialValue) / initialValue) * 100;
    });

    const ctx = chartRef.current.getContext('2d');
    
    try {
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: state.portfolio.history.map(h => 
            new Date(h.timestamp).toLocaleTimeString()
          ),
          datasets: [
            {
              label: 'Portfolio Value',
              data: state.portfolio.history.map(h => h.value),
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgba(75, 192, 192, 0.1)',
              tension: 0.1,
              fill: true,
              yAxisID: 'y',
              hidden: !legendState['Portfolio Value']
            },
            {
              label: 'Total Return %',
              data: returns,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.1)',
              tension: 0.1,
              yAxisID: 'y1',
              borderDash: [5, 5],
              hidden: !legendState['Total Return %']
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: { 
              display: true,
              position: 'top',
              onClick: (evt, legendItem, legend) => {
                const newLegendState = {
                  ...legendState,
                  [legendItem.text]: !legendItem.hidden
                };
                setLegendState(newLegendState);
                // Call the original legend click handler
                const ci = legend.chart;
                ci.getDatasetMeta(legendItem.datasetIndex).hidden = legendItem.hidden ? false : true;
                ci.update();
              }
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (context.datasetIndex === 0) {
                    return `Value: $${formatLargeNumber(context.raw)}`;
                  } else {
                    return `Returns: ${context.raw.toFixed(2)}%`;
                  }
                }
              }
            }
          },
          scales: {
            x: { 
              grid: { display: false }
            },
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
              title: {
                display: true,
                text: 'Portfolio Value ($)'
              },
              ticks: {
                callback: value => '$' + formatLargeNumber(value)
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              beginAtZero: true,
              title: {
                display: true,
                text: 'Returns (%)'
              },
              ticks: {
                callback: value => value.toFixed(1) + '%'
              },
              grid: {
                drawOnChartArea: false // Only show grid lines for left axis
              }
            }
          }
        }
      });
    } catch (error) {
      console.error('Error creating chart:', error);
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [state.portfolio.history, legendState]);

  if (!state?.portfolio?.history) {
    return (
      <Card>
        <Typography level="h4">Portfolio Performance</Typography>
        <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography level="body-sm">Loading portfolio data...</Typography>
        </Box>
      </Card>
    );
  }

  return (
    <Card>
      <Typography level="h4">Portfolio Performance</Typography>
      <Box sx={{ 
        height: 300, 
        position: 'relative',
        width: '100%',
        p: 2,
        bgcolor: 'background.level1'
      }}>
        <canvas 
          ref={chartRef} 
          style={{ 
            width: '100%', 
            height: '100%',
            display: 'block'
          }} 
        />
      </Box>
    </Card>
  );
};

export default PortfolioChart;
