import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Stack,
  IconButton,
  Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useGameState } from '../../../contexts/GameStateContext';
import { useMarket } from '../../../contexts/MarketContext';
import { useNotification } from '../../../contexts/NotificationContext';
import { formatLargeNumber } from '../../../utils/formatters';

const TradeModal = ({ asset, onClose }) => {
  const { state: gameState, dispatch: gameDispatch } = useGameState();
  const { state: marketState, dispatch: marketDispatch } = useMarket();
  const { showNotification } = useNotification();
  const [amount, setAmount] = useState('');
  const [tradeType, setTradeType] = useState('buy');

  const position = marketState.portfolio?.positions?.[asset.symbol] || { shares: 0, avgPrice: 0 };
  const totalCost = Number(amount) * asset.price;
  const canBuy = gameState.money >= totalCost && amount > 0;
  const canSell = position.shares >= amount && amount > 0;

  const handleTrade = () => {
    if (!amount || isNaN(Number(amount))) return;
    if (tradeType === 'buy' && !canBuy) return;
    if (tradeType === 'sell' && !canSell) return;

    const shares = Number(amount);
    const tradeAction = {
      type: 'EXECUTE_TRADE',
      payload: {
        symbol: asset.symbol,
        shares: tradeType === 'buy' ? shares : -shares,
        price: asset.price,
        totalCost: tradeType === 'buy' ? totalCost : -totalCost
      }
    };

    // Execute trade in both contexts
    gameDispatch(tradeAction);
    marketDispatch(tradeAction);

    showNotification({
      title: 'Trade Executed',
      message: `Successfully ${tradeType}ed ${shares} shares of ${asset.symbol}`,
      type: 'success'
    });

    onClose();
  };

  return (
    <Dialog 
      open={!!asset} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: 'background.paper',
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        pb: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Trade {asset?.symbol}
          </Typography>
          <Chip 
            label={asset?.type}
            size="small"
            color="primary"
            sx={{ textTransform: 'uppercase' }}
          />
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Stack direction="row" spacing={1}>
            <Button
              fullWidth
              variant={tradeType === 'buy' ? 'contained' : 'outlined'}
              color="success"
              onClick={() => setTradeType('buy')}
              sx={{
                borderRadius: 2,
                background: tradeType === 'buy' ? (theme) => 
                  `linear-gradient(135deg, ${theme.palette.success.main}, ${theme.palette.success.light})` : 'none',
              }}
            >
              Buy
            </Button>
            <Button
              fullWidth
              variant={tradeType === 'sell' ? 'contained' : 'outlined'}
              color="error"
              onClick={() => setTradeType('sell')}
              sx={{
                borderRadius: 2,
                background: tradeType === 'sell' ? (theme) => 
                  `linear-gradient(135deg, ${theme.palette.error.main}, ${theme.palette.error.light})` : 'none',
              }}
            >
              Sell
            </Button>
          </Stack>

          <TextField
            label="Quantity"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            inputProps={{
              min: 0,
              step: 1
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
              }
            }}
          />

          <Box sx={{
            p: 2,
            borderRadius: 2,
            bgcolor: (theme) => `${theme.palette.info.main}10`,
            border: 1,
            borderColor: (theme) => `${theme.palette.info.main}30`,
          }}>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Price per share: ${formatLargeNumber(asset.price)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total cost: ${formatLargeNumber(totalCost)}
              </Typography>
              {position && (
                <Typography variant="body2" color="text.secondary">
                  Current position: {position.shares} shares
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary">
                Cash available: ${formatLargeNumber(gameState.money)}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{
            borderRadius: 2,
            minWidth: 100
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleTrade}
          variant="contained"
          disabled={tradeType === 'buy' ? !canBuy : !canSell}
          color={tradeType === 'buy' ? 'success' : 'error'}
          sx={{
            borderRadius: 2,
            minWidth: 100,
            background: (theme) => tradeType === 'buy'
              ? `linear-gradient(135deg, ${theme.palette.success.main}, ${theme.palette.success.light})`
              : `linear-gradient(135deg, ${theme.palette.error.main}, ${theme.palette.error.light})`,
            '&:hover': {
              background: (theme) => tradeType === 'buy'
                ? `linear-gradient(135deg, ${theme.palette.success.dark}, ${theme.palette.success.main})`
                : `linear-gradient(135deg, ${theme.palette.error.dark}, ${theme.palette.error.main})`,
            }
          }}
        >
          {tradeType === 'buy' ? 'Buy' : 'Sell'} Shares
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TradeModal;