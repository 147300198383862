import { Box, CssBaseline } from '@mui/material';
import { GameStateProvider, useGameState } from './contexts/GameStateContext';
import { MarketProvider } from './contexts/MarketContext';
import { NotificationProvider } from './contexts/NotificationContext';
import Header from './components/layout/Header';
import GamePanel from './components/game/GamePanel';
import MarketPanel from './components/market/MarketPanel';
import ErrorBoundary from './components/layout/ErrorBoundary';
import { GAME_CONSTANTS } from './data/gameConstants';
import { ThemeProvider } from '@mui/material/styles';
import { theme, darkTheme } from './theme/theme';
import { useState, useEffect } from 'react';

function AppContent() {
  const { state } = useGameState();
  const [isMarketUnlocked, setIsMarketUnlocked] = useState(false);

  useEffect(() => {
    if (state.money >= GAME_CONSTANTS.MARKET.UNLOCK_REQUIREMENT) {
      setIsMarketUnlocked(true);
    }
  }, [state.money]);

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <GamePanel />
        {(state.marketUnlocked || state.money >= GAME_CONSTANTS.MARKET.UNLOCK_REQUIREMENT) && <MarketPanel />}
      </Box>
    </Box>
  );
}

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('theme-mode');
    return savedMode === 'dark';
  });
  
  useEffect(() => {
    localStorage.setItem('theme-mode', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };
  
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
      <NotificationProvider>
        <CssBaseline />
        <ErrorBoundary>
          <GameStateProvider>
            <MarketProvider>
              <Box
                sx={{
                  minHeight: '100vh',
                  bgcolor: 'background.default',
                  transition: 'background-color 0.3s ease'
                }}
              >
                <Header isDarkMode={isDarkMode} onThemeToggle={toggleTheme} />
                <AppContent />
              </Box>
            </MarketProvider>
          </GameStateProvider>
        </ErrorBoundary>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;