import { Button, Stack, Typography, LinearProgress, Box } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { formatLargeNumber } from '../../utils/formatters';
import { calculateTrainingCost, calculateNextImprovement, calculateTrainingProgress } from '../../services/gameService';
import { formatTime } from '../../utils/formatters';

const TrainingButton = () => {
  const { state, dispatch } = useGameState();
  const cost = calculateTrainingCost(state.trainingLevel);
  const canAfford = state.money >= cost;
  const improvement = calculateNextImprovement(state.trainingLevel, state.clickMultiplier);
  const progress = calculateTrainingProgress(state);

  const handleTrain = () => {
    if (canAfford) {
      dispatch({ type: 'TRAIN' });
    }
  };

  return (
    <Button
      variant="contained"
      color={canAfford ? "primary" : "secondary"}
      onClick={handleTrain}
      disabled={!canAfford}
      sx={{ 
        width: '100%',
        minHeight: { xs: '120px', sm: 'auto' },
        p: { xs: 3, sm: 2 },
        transition: 'all 0.2s ease',
        '&:not(:disabled):hover': {
          transform: 'scale(1.02)'
        },
        '&:active': {
          transform: { xs: 'scale(0.98)', sm: 'none' }
        }
      }}
    >
      <Stack 
        spacing={{ xs: 2, sm: 1 }}
        alignItems="center" 
        width="100%"
      >
        <Typography variant="h6">Training Level {state.trainingLevel}</Typography>
        <Stack 
          direction={{ xs: 'row', sm: 'column' }}
          spacing={2}
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Typography variant="body2" color={canAfford ? "success.main" : "text.secondary"}>
            Cost: ${formatLargeNumber(cost)}
          </Typography>
          <Typography variant="body2" color="primary">
            Current: ${formatLargeNumber(state.clickValue)} / click
          </Typography>
          <Typography variant="body2" color="success.main">
            Next: +${formatLargeNumber(improvement)} / click
          </Typography>
        </Stack>
        
        <Box sx={{ width: '100%', mt: { xs: 2, sm: 1 } }}>
          <Stack direction="row" spacing={1} width="100%" alignItems="center">
            <LinearProgress
              variant="determinate"
              value={progress.percentage}
              sx={{ 
                width: '100%',
                height: { xs: '12px', sm: '8px' }
              }}
            />
            <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
              {progress.percentage.toFixed(1)}%
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Button>
  );
};

export default TrainingButton;