import { createContext, useContext, useReducer, useEffect } from 'react';
import { initialGameState } from '../data/gameStatePrefab';
import { GAME_CONSTANTS } from '../data/gameConstants';
import { updateStaffIncome } from '../services/staffService';
import { calculateClickValue, calculateTrainingCost } from '../services/gameService';
import { MarketManager } from '../data/marketDataPrefab';
import { saveGameState, loadGameState } from '../services/storageService';

export const GameStateContext = createContext();

const gameReducer = (state, action) => {
  switch (action.type) {
    case 'WORK_CLICK':
      return {
        ...state,
        money: state.money + state.clickValue
      };

    case 'TRAIN': {
      const cost = calculateTrainingCost(state.trainingLevel);
      if (state.money < cost) return state;

      const newState = {
        ...state,
        money: state.money - cost,
        trainingLevel: state.trainingLevel + 1,
      };

      const newClickValue = calculateClickValue(newState);
      
      return {
        ...newState,
        clickValue: newClickValue
      };
    }

    case 'HIRE_STAFF': {
      const { staffType, cost } = action.payload;
      
      const newStaff = {
        ...state.staff,
        [staffType]: {
          count: (state.staff[staffType]?.count || 0) + 1,
          multiplier: state.staff[staffType]?.multiplier || 1
        }
      };

      const newState = {
        ...state,
        money: state.money - cost,
        staff: newStaff
      };
      
      const newIncome = updateStaffIncome(newState);
      
      return {
        ...newState,
        incomePerSecond: newIncome
      };
    }

    case 'ADD_INCOME':
      return {
        ...state,
        money: state.money + action.payload
      };

    case 'UNLOCK_MARKET': {
      if (state.money < GAME_CONSTANTS.MARKET.UNLOCK_COST) return state;
      
      return {
        ...state,
        money: state.money - GAME_CONSTANTS.MARKET.UNLOCK_COST,
        marketUnlocked: true,
        marketState: {
          ...state.marketState,
          unlocked: true
        }
      };
    }

    case 'UPDATE_MONEY':
      return {
        ...state,
        money: state.money + action.payload.amount
      };
      
    case 'UPDATE_MARKET_PORTFOLIO':
      const { symbol, quantity, price, marketType } = action.payload;
      const currentPosition = state.marketState?.portfolio?.positions?.[symbol] || { shares: 0, avgPrice: 0 };
      const newShares = currentPosition.shares + quantity;
      
      // Calculate new average price for buys
      let newAvgPrice = currentPosition.avgPrice;
      if (quantity > 0) {
        newAvgPrice = ((currentPosition.avgPrice * currentPosition.shares) + (price * quantity)) / newShares;
      }
      
      return {
        ...state,
        marketState: {
          ...state.marketState,
          portfolio: {
            ...state.marketState.portfolio,
            positions: {
              ...state.marketState.portfolio.positions,
              [symbol]: {
                shares: newShares,
                avgPrice: newAvgPrice
              }
            }
          }
        }
      };

    case 'EXECUTE_TRADE': {
      const { totalCost } = action.payload;
      
      // Don't allow if not enough money for buying
      if (totalCost > 0 && state.money < totalCost) return state;

      return {
        ...state,
        money: state.money - totalCost
      };
    }

    case 'PURCHASE_UPGRADE': {
      const upgrade = action.payload;
      console.log('Processing upgrade:', upgrade); // Debug log
      
      if (state.money < upgrade.cost) return state;

      const newStaff = { ...state.staff };

      if (upgrade.affects === 'all') {
        // Apply multiplier to all staff types
        Object.keys(newStaff).forEach(staffType => {
          newStaff[staffType] = {
            ...newStaff[staffType],
            multiplier: (newStaff[staffType].multiplier || 1) * upgrade.multiplier
          };
        });
      } else if (Array.isArray(upgrade.affects)) {
        // Apply multiplier to specified staff types
        upgrade.affects.forEach(staffType => {
          if (newStaff[staffType]) {
            newStaff[staffType] = {
              ...newStaff[staffType],
              multiplier: (newStaff[staffType].multiplier || 1) * upgrade.multiplier
            };
          }
        });
      } else {
        console.error('Invalid upgrade affects property:', upgrade.affects);
        return state;
      }

      // Calculate new income with updated multipliers
      const newState = {
        ...state,
        money: state.money - upgrade.cost,
        staff: newStaff,
        upgrades: {
          ...state.upgrades,
          [upgrade.id]: true
        }
      };
      
      const newIncome = updateStaffIncome(newState);

      return {
        ...newState,
        incomePerSecond: newIncome
      };
    }

    case 'RESET_GAME':
      return initialGameState;

    default:
      return state;
  }
};

export const GameStateProvider = ({ children }) => {
  // Load saved state or use initial state
  const [state, dispatch] = useReducer(gameReducer, null, () => {
    const savedState = loadGameState();
    return savedState || initialGameState;
  });

  // Save state whenever it changes
  useEffect(() => {
    saveGameState(state);
  }, [state]);

  // Add this new effect for income generation
  useEffect(() => {
    const incomeInterval = setInterval(() => {
      if (state.incomePerSecond > 0) {
        dispatch({ 
          type: 'ADD_INCOME', 
          payload: state.incomePerSecond 
        });
      }
    }, 1000);

    return () => clearInterval(incomeInterval);
  }, [state.incomePerSecond]);

  return (
    <GameStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GameStateContext.Provider>
  );
};

export const useGameState = () => {
  const context = useContext(GameStateContext);
  if (!context) {
    throw new Error('useGameState must be used within a GameStateProvider');
  }
  return context;
};
